import { graphql } from 'gatsby'
import React from 'react'
import { isNil } from 'lodash'
import { SlideshowTypeKeyed, getKeyedSlideshowSchema } from '../../common/shared/schema12'
import SlideShow from '../../components/slideshows/Schema12'
import Wrapper from '../../components/slideshows/Wrapper'

type ComponentProps = {
  // search: any
  data: {
    allObjects: {
      edges: Array<any>
    }
  }
  pageContext: any
}

// const SchemaLoader:FunctionComponent<ComponentProps> = React.memo(({ data, search }:ComponentProps):JSX.Element => {
const SchemaLoader = (props:ComponentProps):any => {
	// const SchemaLoader:React.Component<ComponentProps, CounterState> =() => {
	// const { name, display } = search
	const {
		data, pageContext,
	} = props
	const { social, cdnUrl, url } = pageContext
	const { allObjects } = data
	const { edges } = allObjects
	const { node } = edges[0]

	// console.log(`pageContext=${JSON.stringify(pageContext)}`)

	if (isNil(node)) {
		throw new Error('Stickie not found')
	}

	const tempSlideshow = getKeyedSlideshowSchema(node)
	const slideshow: SlideshowTypeKeyed = {
		...tempSlideshow,
		social,
		sticker: {
			...tempSlideshow.sticker,
			cdnUrl,
		},
		url,
	}

	return (
		<>
			<Wrapper slideshow={slideshow}>
				<SlideShow />
			</Wrapper>
		</>
	)
}

export default SchemaLoader

export const Schema12Query = graphql`
  query Schema12ObjectQuery($slug: String!) {
    allObjects(filter: { slug: { eq: $slug } }) {
      edges {
        node {
					id
					slug
					shareText
          ogTitle
					ogTags
					tags
					sticker {
						url
						scale
						left
						top
						rotation
						background {
							url
							color
						}
    			},
          name
					textboxOffset
					customize {
						template
						validation
						maxLength
					}
					scoreboard {
						label
						scoreFormat
						tagline
					}
					startSlide
					startSlides
          slides {
						id
						points
						textRenderEffect
						messageText
						messageTexts {
							message
							isEditable
						}
						defaultPlaceholderText
						messageClassName
            moves {
							label
							targetIds
							trackId
						}
            effects {
              url
							urls
							type
							left
							top
							scale
							scaleX
							animation {
								type
								delay
								duration
								rotation
								rotations
							}
							rotation
							rotations
							zIndex
							opacity
							text {
								color
								text
							}
							vibration {
								pattern
							}
							sound {
								url
								delay
								loop
							}
            }
          }
        }
      }
    }
  }
`
